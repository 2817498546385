import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/contact-form"

const Contato = ({ data, location }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData
  const pageTitle = "Contato"

  return (
    <Layout description={description} logo={logo}>
      <Seo
        title={pageTitle}
        description="Espaço para entrar em contato com o blog."
      />
      <div className="post-wrapper">
        <h1 itemProp="headline">{pageTitle}</h1>
        <div className="post-text">
          <p>Olá!</p>

          <p>Quer conversar comigo no privado?</p>

          <p>
            Informe seu nome, e-mail e deixe sua mensagem abaixo que eu retorno
            assim que possível (pode demorar um pouco).
          </p>

          <p>
            Seu nome e e-mail serão utilizados apenas para entrar em contato
            contigo e ficarão armazenados no e-mail do blog, mas não serão
            divulgados para outras pessoas.
          </p>

          <p>
            <b>Atenção:</b> não trabalho com cidadania italiana e não sou
            profissional do Direito. Informações divulgadas em meu blog refletem
            apenas minhas experiências pessoais e conteúdo que pesquiso. Por
            conta disso, não posso oferecer ajuda profissional ou me comprometer
            dando orientação em burocracias ou processos que você vê nos meus
            textos.
          </p>

          <p>Abraço!</p>

          <p>(Todos os campos do formulário são obrigatórios.)</p>
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default Contato

export const pageQuery = graphql`
  {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
