import React from "react"
import { useForm } from "@formspree/react"

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xnqoyryl")
  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="nome">
        Nome:
        <input
          className="form-field"
          type="text"
          name="nome"
          id="nome"
          required
        />
      </label>
      <label htmlFor="email">
        E-mail:
        <input
          className="form-field"
          type="email"
          name="email"
          id="email"
          required
        />
      </label>
      <label htmlFor="assunto">
        Assunto:
        <input className="form-field" type="text" name="assunto" id="assunto" />
      </label>
      <label htmlFor="mensagem">
        Mensagem:
        <textarea
          className="form-field"
          name="mensagem"
          id="mensagem"
          rows="5"
          required
        />
      </label>
      <button className="basic-button" type="submit">
        Enviar
      </button>
      <input className="basic-button attention" type="reset" value="Limpar" />
      {state.succeeded && <p>{"Mensagem enviada! Obrigado... :)"}</p>}
      {state.errors && state.errors.length > 0 && (
        <p>
          Infelizmente, algo deu errado. Por favor, tente de novo mais tarde.
        </p>
      )}
    </form>
  )
}

export default ContactForm
